import React, { useState, useEffect, useCallback, useRef } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import MuiPhoneNumber from "material-ui-phone-number-2";
import { formatPostCode } from "common-utils/utils/formatter";
import {
  callableFunctions,
  getRequestUI,
  postRequestUI,
  deleteRequestUI,
  putRequestUI,
} from "common-utils/utils/api";
import {
  AddButton,
  GoogleAutoCompleteAddress,
} from "@shared/components/lib/index";
import { Autocomplete } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton as CIconButton } from "@shared/components/lib/index";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { defaultCountry } from "common-utils/utils/country";
import { styled } from "@mui/system";
import useTheme from "@mui/material/styles/useTheme";
import { isPostcodeValid } from "common-utils/utils/validation";

const useStyles = (theme) => ({
  info: {
    paddingTop: 10,
    textAlign: "center",
    fontFamily: 16,
  },
  error: {
    color: "red",
  },
  addButton: {
    position: "fixed !important",
    right: "16px !important",
    bottom: "16px !important",
  },
  table: {
    minWidth: 650,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    display: "flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonSubmit: {
    borderRadius: "40 !important",
    color: "#fff  !important",
    backgroundColor: "#0635C9 !important",
    maxWidth: "170 !important",
    textTransform: "none !important",
    marginTop: "5 !important",
    marginBottom: "10 !important",
    borderRadius: "40 !important",
    backgroundColor: "#0635C9 !important",
    fontFamily: "Nunito, sans-serif !important",
    fontWeight: "bold !import",
  },
  buttonCansel: {
    textTransform: "none",
  },
  dialogBox: {
    maxWidth: 491,
    maxHeight: 580,
    margin: "180 auto auto",
    [theme.breakpoints.down("xs")]: {
      margin: "50 auto auto",
    },
  },
  labelRoot: {
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  circleProgress2: {
    color: "#7F8FA4",
  },
  circleProgress: {
    color: "#fff",
  },
});

const StyledTableRow = styled(TableRow)((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#f7f7f7",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RetailerLocations(props) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [locations, setLocations] = useState([]);
  const [name, setLocationName] = useState("");
  const [postcode, setPostcode] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [houseAdditions, setHouseAdditions] = useState("");
  const [houseAddition, setHouseAddition] = useState("");
  const [personName, setPersonName] = useState("");
  const [personNumber, setPersonNumber] = useState("");
  const [returnAddress, setReturnAddress] = useState({});
  const [selectedCarrier, setSelectedCarrier] = useState([]);
  const [newLocationloading, setNewLocationLoading] = useState(false);
  const [newLocationSuccess, setNewLocationSuccess] = useState(false);
  const [streetName, setStreetName] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showAdressCheckProgress, setShowAdressCheckProgress] = useState(false);
  const [dropOfCoordinates, setDropOfCoordinates] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [errorLocationName, setErrorLocationName] = useState("");
  const [errorHouseAddition, setErrorHouseAddition] = useState("");
  const [errorPostcode, setErrorPostcode] = useState("");
  const [errorHouseNumber, setErrorHouseNumber] = useState("");
  const [errorPersonName, setErrorPersonName] = useState("");
  const [errorPersonNumber, setErrorPersonNumber] = useState("");
  const [errorCarrier, setErrorCarrier] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [deletingLocation, setDeletingLocation] = useState(null);
  const [valueDefaultLocation, setValueDefaultLocation] = useState(null);
  const [defaultLocationLoader, setDefaultLocationLoader] = useState(null);
  const [isPartner, setIsPartner] = useState(false);
  const [country, setCountry] = useState(defaultCountry);
  const [countries, setCountries] = useState([]);
  const [coordinates, setCoordinates] = useState({});
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [reference, setReference] = useState("");
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [showSenderAddressOnLabel, setShowSenderAddressOnLabel] =
    useState(false);
  const [selectedType, setSelectedType] = useState("");
  const isNLCountryCode = country && country.code === defaultCountry.code;

  useEffect(() => {
    getDefaultLocation();
  }, []);

  useEffect(() => {
    let notExist = true;
    for (const key in locations) {
      if (locations[key].id === valueDefaultLocation) {
        notExist = false;
      }
    }
    if (locations.length >= 1 && (!valueDefaultLocation || notExist)) {
      changeDefaultLocation(locations[0].id);
    }
  }, [locations]);

  useEffect(() => {
    delayedAdddressChecked(postcode, houseNumber);
    setErrorAddress("");
  }, [postcode, houseNumber]);

  useEffect(() => {
    if (isOpenDialog) {
      getCountries();
    }
  }, [isOpenDialog]);

  const getCountries = () => {
    return getRequestUI("/countries")
      .then((result) => {
        setCountries(result);
        if (!country) {
          setCountry(defaultCountry);
        }
      })
      .catch((error) => {
        var code = error.code;
        var message = error.message;
      });
  };

  const updateTable = () => {
    setShowProgress(true);
    return getRequestUI("/locations/pickup")
      .then((result) => {
        setLocations(result);
        setShowProgress(false);
      })
      .catch((error) => {
        setShowProgress(false);
        var code = error.code;
        var message = error.message;
      });
  };

  const handleOpen = () => {
    setIsOpenDialog(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    clearForm();
  };

  const clearForm = () => {
    setLocationName("");
    setShowError(false);
    setPostcode("");
    setHouseNumber("");
    setHouseAdditions("");
    setPersonName("");
    setPersonNumber("");
    setReference("");
    setSelectedCarrier({});
    setStreet("");
    setCity("");
    setCountry(defaultCountry);
    clearErrors();
    setCoordinates(null);
    setShowSenderAddressOnLabel(false);
    setSelectedType("");
  };

  const clearErrors = () => {
    setErrorMessage("");
    setShowError(false);
    setShowProgress(false);
    setErrorLocationName("");
    setErrorHouseAddition("");
    setErrorPostcode("");
    setErrorHouseNumber("");
    setErrorPersonName("");
    setErrorPersonNumber("");
    setErrorCarrier("");
    setErrorAddress("");
  };

  const chooseLocationName = (val) => {
    if (!val) {
      setErrorLocationName("Name is empty");
    } else {
      setErrorLocationName("");
    }
    setLocationName(val);
  };

  const choosePostcode = (val) => {
    let normalizedPostcode = formatPostCode(val);
    if (!val) {
      setErrorPostcode("Please fill in ZipCode");
    } else if (normalizedPostcode === "") {
      setErrorPostcode("ZipCode is invalid");
    } else {
      setErrorPostcode("");
    }
    setPostcode(val);
  };

  const chooseHouseNumber = (val) => {
    if (!val) {
      setErrorHouseNumber("HouseNumber is invalid");
    } else {
      setErrorHouseNumber("");
    }
    setHouseNumber(val);
  };

  const chooseHouseAddition = (val) => {
    if (!val) {
      setErrorHouseAddition("Please fill in House Addition");
    } else {
      setErrorHouseAddition("");
    }
    setHouseAddition(val);
  };

  const choosePersonName = (val) => {
    if (!val) {
      setErrorPersonName("Please select person name");
    } else {
      setErrorPersonName("");
    }
    setPersonName(val);
  };

  const choosePersonNumber = (val) => {
    if (!val) {
      setErrorPersonNumber("Please select person phone number");
    } else {
      setErrorPersonNumber("");
    }
    setPersonNumber(val);
  };

  const delayedAdddressChecked = useRef(
    _.debounce(
      (postcode, houseNumber) => addressChanged(postcode, houseNumber),
      300
    )
  ).current;

  function isEmpty(obj) {
    for (let key in obj) {
      return false;
    }
    return true;
  }

  const addressChanged = (postcode, houseNumber) => {
    if (!postcode) {
      return;
    }
    setDisableButton(true);
    let normalizedPostcode = formatPostCode(postcode);
    let addressQueryValid =
      isPostcodeValid(normalizedPostcode) && parseInt(houseNumber);
    setStreetName("");
    if (isNLCountryCode) {
      if (addressQueryValid) {
        setShowAdressCheckProgress(true);
        getRequestUI("/retailer/house/additions", {
          postcode: normalizedPostcode,
          houseNumber: houseNumber,
        })
          .then((result) => {
            let coordinates = {
              lat: result.coordinates.latitude,
              lng: result.coordinates.longitude,
            };

            setDropOfCoordinates(coordinates);
            let street = result.street;
            let availableHouseNumbers = result.houseAdditions;
            setStreetName(street);
            setHouseAdditions(availableHouseNumbers);
            setShowAdressCheckProgress(false);
            setErrorAddress("");
            setDisableButton(false);
          })
          .catch((e) => {
            setStreetName("");
            setHouseAdditions("");
            setShowAdressCheckProgress(false);
            setErrorAddress("Please select correct address");
          });
      } else {
        setDisableButton(false);
      }
    }
  };

  const validate = () => {
    if (!name) {
      chooseLocationName();
      return false;
    }

    if (!postcode) {
      choosePostcode();
      return false;
    }

    if (!houseNumber) {
      chooseHouseNumber();
      return false;
    }

    if (!personName) {
      choosePersonName();
      return false;
    }

    if (houseAdditions != "") {
      if (!houseAddition) {
        chooseHouseAddition();
        return false;
      }
    }

    if (!personNumber) {
      choosePersonNumber();
      return false;
    }

    return true;
  };

  const handleSave = () => {
    setShowError(false);
    if (!validate()) {
      return validate();
    }
    let location = {
      name: name,
      postcode: postcode,
      houseNumber: houseNumber,
      houseAddition: houseAdditions.length >= 1 ? houseAddition : "",
      personName,
      personNumber,
      country,
      city,
      street,
      coordinates,
      reference,
      returnAddress,
      showSenderAddressOnLabel,
    };
    setNewLocationSuccess(false);
    setNewLocationLoading(true);
    postRequestUI("/retailer/location", location)
      .then((res) => {
        handleClose();
      })
      .then(() => {
        setNewLocationSuccess(true);
        setNewLocationLoading(false);
        updateTable();
      })
      .catch((error) => {
        // Getting the Error details.
        setNewLocationSuccess(false);
        setNewLocationLoading(false);
        var code = error.code;
        var message = error.message;

        if (code === "invalid-argument") {
          setErrorMessage(message);
          setShowError(true);
        }
      });
  };

  const handleEditLocation = () => {
    if (!validate()) {
      return validate();
    }
    let data = {
      locationId: locationId,
      name: name,
      personName: personName,
      personNumber: personNumber,
      address: {
        postcode,
        houseAdditions: houseAdditions.length >= 1 ? houseAddition : "",
        houseNumber,
        country,
      },
      reference,
      returnAddress,
      showSenderAddressOnLabel,
      type: selectedType,
    };
    if (!isNLCountryCode) {
      data.city = city;
      data.street = street;
      data.coordinates = coordinates;
    }

    setNewLocationSuccess(false);
    setNewLocationLoading(true);
    putRequestUI("/retailer/location", data)
      .then(function (result) {
        handleClose();
      })
      .then(function () {
        setNewLocationSuccess(true);
        setNewLocationLoading(false);
        updateTable();
      })
      .catch(function (error) {
        // Getting the Error details.
        setNewLocationSuccess(false);
        setNewLocationLoading(false);
        var code = error.code;
        var message = error.message;

        if (code === "invalid-argument") {
          setErrorMessage(message);
          setShowError(true);
        }
      });
  };

  const handleCarrier = useCallback((e, value) => {
    if (value) {
      setSelectedCarrier(value);
    }
  }, []);

  const editLocation = (location) => {
    setIsOpenDialog(true);
    setEdit(true);
    setLocationId(location.id);
    setLocationName(location.name);
    setPostcode(location.address.postcode);
    setHouseNumber(location.address.houseNumber);
    setHouseAddition(location.address.houseAdditions);
    setPersonName(location.personName);
    setPersonNumber(location.personNumber);
    setCountry(location.address.country);
    setCity(location.city);
    setStreet(location.street);
    setCoordinates(location.coordinates);
    setReference(location.reference);
    setReturnAddress(location.returnAddress);
    setShowSenderAddressOnLabel(location.showSenderAddressOnLabel);
    setSelectedType(location.type);
  };

  const getDefaultLocation = () => {
    setNewLocationLoading(true);
    setShowProgress(true);
    return getRequestUI("/location/default", {})
      .then(function (result) {
        setValueDefaultLocation(result.defaultLocation);
      })
      .then(function (result) {
        setShowProgress(false);
        updateTable();
        setNewLocationLoading(false);
      })
      .catch(function (error) {
        // Getting the Error details.
        setShowProgress(false);
        var code = error.code;
        var message = error.message;
      });
  };

  const handleCountry = useCallback((e, value) => {
    if (!value) {
      setCountry({});
    } else {
      setCountry(value);
    }
    setCity("");
    setStreet("");
    setPostcode("");
    setHouseAdditions("");
    setHouseNumber("");
    setCoordinates(null);
  }, []);

  const onAddressResolved = (autoCompleteData) => {
    setShowError(false);
    setErrorMessage("");
    const { coordinates, postcode, houseNumber, city, street } =
      autoCompleteData;
    if (!postcode || !city) {
      setErrorAddress("Unable to locate an address");
      setDisableButton(true);
      return;
    }
    if (!houseNumber) {
      setErrorAddress("Please fill house number");
      setDisableButton(true);
      return;
    }
    if (!street) {
      setErrorAddress("Please fill the street");
      setDisableButton(true);
      return;
    }
    setDisableButton(false);
    setCoordinates(coordinates);
    setPostcode(postcode);
    setHouseNumber(houseNumber);
    setCity(city);
    setStreet(street);
  };

  const handleDelete = (id) => {
    const deleteId = id;
    setDeletingLocation(deleteId);
    deleteRequestUI("/retailer/location", { id: deleteId })
      .then(function () {
        if (deleteId === valueDefaultLocation) {
          changeDefaultLocation(null);
        }
        getDefaultLocation();
      })
      .then(function () {
        updateTable();
      })
      .catch(function (error) {})
      .finally(function () {
        setDeletingLocation();
      });
  };

  const handleChangeDefaultLocation = (event) => {
    changeDefaultLocation(Number(event.target.value));
  };

  const changeDefaultLocation = (value) => {
    const data = {
      locationId: value,
    };
    setDefaultLocationLoader(value);
    putRequestUI("/location/default", data)
      .then(function (result) {
        setValueDefaultLocation(result.defaultLocation);
        setDefaultLocationLoader(null);
      })
      .catch(function (error) {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;

        if (code === "invalid-argument") {
          setErrorMessage(message);
          setShowError(true);
        }
      });
  };

  return (
    <Box
      flexDirection="column"
      alignItems="center"
      style={{ padding: "100 0", height: "100vh", background: "#EFF3F6" }}
    >
      {!isPartner && (
        <AddButton
          withChat={true}
          sx={styles.addButton}
          bottom={100}
          onClick={handleOpen}
        />
      )}
      <Dialog
        open={open || edit}
        fullScreen
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={styles.dialogBox}
      >
        <DialogContent>
          <Grid
            container
            item
            sm={12}
            style={{ paddingTop: 20, height: "100%" }}
          >
            <Grid
              item
              sm={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div>
                <Grid>
                  <div
                    style={{ fontSize: 22, fontWeight: 500, marginBottom: 10 }}
                  >
                    {edit ? "Edit location" : "Create new location"}
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <TextField
                      fullWidth
                      id="name"
                      label="Location Name *"
                      InputLabelProps={{
                        classes: { root: styles.labelRoot },
                      }}
                      value={name || ""}
                      error={errorLocationName}
                      helperText={errorLocationName}
                      onChange={(e) => chooseLocationName(e.target.value)}
                    />
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <Autocomplete
                      id="country-autocomplete"
                      autoComplete
                      autoHighlight
                      value={country}
                      options={countries || []}
                      getOptionLabel={(option) => option.name ?? ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Select Country"
                          placeholder="Select country"
                          error={errorCarrier}
                        />
                      )}
                      onChange={handleCountry}
                    />
                  </div>
                  {isNLCountryCode ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "50%", marginBottom: 10 }}>
                        <TextField
                          id="postcode-field"
                          label="Zipcode *"
                          InputLabelProps={{
                            classes: { root: styles.labelRoot },
                          }}
                          value={postcode.trim() || ""}
                          error={errorPostcode}
                          helperText={errorPostcode}
                          onChange={(e) => choosePostcode(e.target.value)}
                        />
                      </div>
                      <div
                        style={{
                          width: "50%",
                          marginLeft: 10,
                          marginBottom: 10,
                        }}
                      >
                        <TextField
                          id="house-number-field"
                          label="House Number *"
                          InputLabelProps={{
                            classes: { root: styles.labelRoot },
                          }}
                          value={houseNumber || ""}
                          error={errorHouseNumber}
                          helperText={errorHouseNumber}
                          onChange={(e) => chooseHouseNumber(e.target.value)}
                        />
                      </div>
                      <div style={{ width: "43%", marginBottom: 10 }}>
                        {houseAdditions.length >= 1 && (
                          <TextField
                            select
                            fullWidth
                            InputLabelProps={{
                              classes: {
                                root: styles.labelRoot,
                              },
                            }}
                            label="House Number Additions"
                            id="house-addition"
                            error={errorHouseAddition}
                            value={houseAddition || ""}
                            helperText={errorHouseAddition}
                            onChange={(e) =>
                              chooseHouseAddition(e.target.value)
                            }
                          >
                            {houseAdditions.map((addition) => (
                              <MenuItem value={addition} key={addition}>
                                {addition}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      </div>
                      <div style={{ marginTop: 20, marginBottom: 10 }}>
                        {showAdressCheckProgress && (
                          <CircularProgress
                            sx={styles.circleProgress2}
                            size={20}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <GoogleAutoCompleteAddress
                        country={country}
                        onSelect={onAddressResolved}
                        address={{ city, street, houseNumber }}
                      />
                    </>
                  )}
                  <div style={{ marginBottom: 10 }}>
                    <TextField
                      fullWidth
                      id="person-name"
                      label="Name Contactperson"
                      InputLabelProps={{
                        classes: { root: styles.labelRoot },
                      }}
                      value={personName}
                      error={errorPersonName}
                      helperText={errorPersonName}
                      onChange={(e) => choosePersonName(e.target.value)}
                    />
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <MuiPhoneNumber
                      id="person-number"
                      label="Number Contactperson"
                      defaultCountry="nl"
                      regions={"europe"}
                      fullWidth
                      InputLabelProps={{
                        classes: { root: styles.labelRoot },
                      }}
                      value={personNumber}
                      error={errorPersonNumber}
                      helperText={errorPersonNumber}
                      onChange={(value) => choosePersonNumber(value)}
                    />
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <TextField
                      fullWidth
                      data-testid="location_reference_field"
                      id="location_reference_field"
                      label="Reference"
                      value={reference}
                      onChange={(e) => setReference(e.target.value)}
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid="show_sender_address_on_label"
                        checked={showSenderAddressOnLabel}
                        onChange={(e) =>
                          setShowSenderAddressOnLabel(e.target.checked)
                        }
                        name="show_sender_address_on_label"
                        color="primary"
                      />
                    }
                    label="Show Sender Address On label"
                  />
                </Grid>
              </div>
              <div>
                <div style={{ margin: "20 0 0" }}>
                  {errorAddress && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: 12,
                        color: "#ff0000",
                      }}
                    >
                      {errorAddress}
                    </div>
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    rounded={50}
                    fullWidth
                    disabled={showProgress || errorAddress || disableButton}
                    variant="contained"
                    classes={{ contained: styles.buttonSubmit }}
                    onClick={edit ? handleEditLocation : handleSave}
                  >
                    {newLocationloading ? (
                      <CircularProgress size={20} sx={styles.circleProgress} />
                    ) : edit ? (
                      "Update Location"
                    ) : (
                      " Save Location"
                    )}
                  </Button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="inherit"
                    onClick={handleClose}
                    classes={{ contained: styles.buttonCansel }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <div
        style={{
          margin: "0 auto",
          maxWidth: 1000,
          background: "#fff",
          padding: "20 0 50",
        }}
      >
        <Grid
          item
          sm={12}
          md={2}
          style={{ display: "flex", alignItems: "Flex-end", paddingBottom: 20 }}
        >
          <div style={{ fontSize: 24, fontWeight: "bold", padding: "16" }}>
            Locations
          </div>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={styles.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {!isPartner && <TableCell></TableCell>}
                <TableCell>Name</TableCell>
                <TableCell>Zipcode</TableCell>
                <TableCell>Street Name</TableCell>
                <TableCell>House Number</TableCell>
                <TableCell>House Addition</TableCell>
                <TableCell>Name Contactperson</TableCell>
                <TableCell>Number Contactperson</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {/* <TableCell >Carrier</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {locations.map((location) => (
                <StyledTableRow key={location.id}>
                  {!isPartner && (
                    <TableCell>
                      <Button
                        endIcon={<EditIcon />}
                        onClick={() => editLocation(location)}
                      />
                    </TableCell>
                  )}
                  <TableCell>{location.name}</TableCell>
                  <TableCell>{location.address.postcode}</TableCell>
                  <TableCell>{location.street}</TableCell>
                  <TableCell>{location.address.houseNumber}</TableCell>
                  <TableCell>{location.address.houseAdditions}</TableCell>
                  <TableCell>{location.personName}</TableCell>
                  <TableCell>{location.personNumber}</TableCell>
                  <TableCell>
                    {deletingLocation === location.id ? (
                      <CircularProgress size={24} sx={styles.deleteProgress} />
                    ) : (
                      <CIconButton
                        icon="delete"
                        onClick={(e) => handleDelete(location.id)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={valueDefaultLocation}
                      onChange={(e) => handleChangeDefaultLocation(e)}
                    >
                      {defaultLocationLoader === location.id ? (
                        <CircularProgress
                          size={24}
                          sx={styles.deleteProgress}
                        />
                      ) : (
                        <FormControlLabel
                          value={location.id}
                          control={<Radio />}
                        />
                      )}
                    </RadioGroup>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
}

export default RetailerLocations;
