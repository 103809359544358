import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Button, Avatar, Grid, List } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { NavLink } from "react-router-dom";
import theme from "@shared/components/lib/styles/theme";

const drawerWidth = 240;

const useStyles = (theme) => ({
  bringlyLogo: {
    maxWidth: 100,
    minWidth: 100,
  },
  appBar: {
    "&.MuiPaper-root": {
      backgroundColor: "white",
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.common.black,
    },
    "&.MuiButtonBase-root": {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  },
  topnav: {
    display: "none",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.common.white,
  },
  toolbar: theme.mixins.toolbar,
  navItem: {
    margin: 2,
    "& a": {
      textDecoration: "none",
      color: theme.palette.common.black,
      fontSize: 20,
      display: "flex",
    },
    "& div": {
      paddingLeft: 1,
    },
  },
  navItemDrawer: {
    margin: "16px 32px",
  },
  activeItemNav: {
    fontWeight: "500",
    color: "#0635C9",
    paddingBottom: 1,
  },
  activeItemDrawer: {
    fontWeight: "500",
    color: theme.palette.common.black,
    paddingLeft: theme.spacing(1),
    borderLeft: "3px solid black",
  },
  drawerContainer: {
    height: "100vh",
    paddingTop: theme.spacing(4),
    backgroundColor: "white",
  },
});

const CustomAppBar = ({
  pages,
  appName,
  mobileOpen,
  setMobile,
  anchorEl,
  setAnchorEl,
  pageReload,
  setPageReloading,
  handleLogout,
}) => {
  const styles = useStyles(theme);

  useEffect(() => {
    setMobile(mobileOpen);
  }, [mobileOpen]);

  const handleDrawerToggle = () => {
    setMobile(!mobileOpen);
  };

  const handlePageReload = (isReloadPage) => {
    if (isReloadPage) {
      setPageReloading(!pageReload);
    }
  };

  const drawer = (
    <List>
      {pages.map((route) => (
        <Grid
          key={route.name}
          sx={{ ...styles.navItem, ...styles.navItemDrawer }}
        >
          <NavLink
            end
            to={route.path}
            style={({ isActive }) =>
              isActive ? styles.activeItemDrawer : undefined
            }
            onClick={handleDrawerToggle}
          >
            {route.icon}
            {route.name}
          </NavLink>
        </Grid>
      ))}
      <Grid sx={{ ...styles.navItem, ...styles.navItemDrawer }}>
        <NavLink
          end
          to="/"
          onClick={handleLogout}
          style={styles.activeItemDrawer}
        >
          Log Out
        </NavLink>
      </Grid>
    </List>
  );

  const topnav = (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item>
        <Grid container>
          {pages.map((route) => (
            <React.Fragment key={route.name}>
              {!route.isHidden && (
                <Grid sx={styles.navItem}>
                  <NavLink
                    end
                    to={route.path}
                    style={({ isActive }) =>
                      isActive ? styles.activeItemNav : undefined
                    }
                    onClick={() => handlePageReload(route.isReloadPage)}
                  >
                    {route.icon}
                    <Grid item data-testid={route.name}>
                      {route.name}
                    </Grid>
                  </NavLink>
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      <Grid sx={styles.navItem}>
        <NavLink end to="/" onClick={handleLogout} style={styles.activeItemNav}>
          Log Out
        </NavLink>
      </Grid>
    </Grid>
  );

  return (
    <>
      <AppBar position="fixed" sx={styles.appBar}>
        <Toolbar style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={styles.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Toolbar>
            <img alt="" src="blackandblue.png" style={styles.bringlyLogo} />
          </Toolbar>
          <Grid sx={styles.topnav}>{topnav}</Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={styles.drawer}
        variant="temporary"
        styles={{
          paper: styles.drawerPaper,
        }}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        anchor="left"
      >
        <Grid container flexDirection="column" sx={styles.drawerContainer}>
          <Grid container justifyContent="end">
            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon style={{ fill: "#000" }} />
            </IconButton>
          </Grid>
          {drawer}
        </Grid>
      </Drawer>
    </>
  );
};

export default CustomAppBar;
