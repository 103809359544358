import React, { useState } from "react";
import { OrderMap } from "@shared/components/lib/index";
import NewDeliveryOrder from "./NewDeliveryOrder";

const NewDelivery = (props) => {
  return (
    <>
      <NewDeliveryOrder userClaims={props.userClaims} />
    </>
  );
};

export default NewDelivery;
