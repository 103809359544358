import RetailerOrders from "./pages/RetailerOrders";
import NewDelivery from "./pages/NewDelivery";
import RetailerLocations from "./pages/RetailerLocations";
import RetailerData from "./pages/RetailerData";
import Claims from "./pages/Claims";

import {
  IconDelivery,
  IconScheduled,
  IconLocation,
  IconAnalytics,
} from "@shared/components/lib/index";

export const formatStoreManagerPages = (props) => [
  {
    path: "/orders",
    name: "Orders",
    element: <RetailerOrders userClaims={props} />,
  },
  {
    path: "/delivery",
    name: "New Delivery",
    element: <NewDelivery userClaims={props} />,
    isReloadPage: true,
  },
  // {
  //   path: "/data",
  //   name: "Data",
  //   element: <RetailerData userClaims={props} />,
  //   icon: <IconAnalytics color={"#fff"} />,
  // },
  {
    path: "/claims",
    name: "Claims",
    element: <Claims />,
  },
];

export const formatRetailerPages = (props) => [
  ...formatStoreManagerPages(props),
  {
    path: "/locations",
    name: "Locations",
    element: <RetailerLocations />,
  },
];
