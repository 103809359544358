import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import conf from "common-utils/conf";
import {
  SignIn,
  ResetPassword,
  ForgotPassword,
} from "@shared/components/lib/index";
import CustomAppBar from "../src/components/CustomAppBar";
import { formatRetailerPages, formatStoreManagerPages } from "./routers";
import { useLocalStorage } from "usehooks-ts";
import { postRequestUI } from "common-utils/utils/api";

export default function RetailerApp() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [pageReload, setPageReload] = useState(false);
  const [userClaims, setUserClaims] = useState({});
  const [auth, setAuth] = useLocalStorage("auth", null);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth) {
      authUser();
    } else {
      cleanState();
    }
  }, [auth]);

  const cleanState = () => {
    setUserClaims({});
    setAnchorEl(null);
  };

  const signOut = async () => {
    await postRequestUI("/logout");
    cleanState();
    setAuth(null);
    navigate("/login");
  };

  const authUser = async () => {
    try {
      const { role, email } = auth;
      if (!role.userType) {
        signOut();
        return;
      }
      if (role.userType !== "retailer" && role.userType !== "storeManager") {
        signOut();
        return;
      }
      setUserClaims({
        userType: role.userType,
        retailerId: role.retailerId,
        locationId: role.defaultLocationId,
      });

      setTimeout(function () {
        window.Trengo.contact_data = {
          email: email,
          name: email,
          custom_fields: [
            {
              field_id: email,
              user_email: email,
            },
          ],
        };
        if (window.Trengo.Api && window.Trengo.Api.Widget) {
          window.Trengo.Api.Widget.render();
        }
      }, 2500);

      return;
    } catch (error) {
      signOut();
    }
  };

  const props = { ...userClaims, pageReload };
  const pages =
    userClaims.userType === "retailer"
      ? formatRetailerPages(props)
      : formatStoreManagerPages(props);

  const isUser =
    userClaims.userType === "retailer" ||
    userClaims.userType === "storeManager";

  return (
    <>
      {!auth && (
        <Routes>
          <Route path="/forgot-password/:token?" element={<ForgotPassword />} />
          <Route
            path="/reset-password/:token?"
            element={<ResetPassword appUrl={conf.carrier} />}
          />
          <Route path="/login" element={<SignIn />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
      {isUser && (
        <>
          <CustomAppBar
            pages={pages}
            appName={"R"}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            mobileOpen={mobileOpen}
            setMobile={setMobileOpen}
            pageReload={pageReload}
            setPageReloading={setPageReload}
            handleLogout={signOut}
          />
          <Routes>
            <Route path="/" element={<Navigate to="/orders" replace />} />
            {pages.map((route) => (
              <Route
                key={route.name}
                path={route.path}
                element={route.element}
              />
            ))}
            <Route path="*" element={<Navigate to="/orders" replace />} />
          </Routes>
        </>
      )}
    </>
  );
}
